module.exports={

                   /* DEV Server URL'S 
   =================================================== */
  //     URL:'https://tests.noton.dev/cw-noton/graphql',
  //     Auth:'https://identity-test.mycw.in/cw-identity/oauth/token',


                  /* Test Server URL'S 
   =================================================== */
         // URL:'https://s20q1.mycw.in/cw-noton/graphql',
         // Auth:'https://s20q1.mycw.in/cw-identity/oauth/token'



/* OFC Server URL'S 
=================================================== */

 //       URL:'https://sofc-test-analytics.noton.dev/cw-noton/graphql',
 //       Auth:'https://auth-ofc-test-analytics.mycw.in/cw-identity/oauth/token'

/* OFC-Live Server URL'S 
=================================================== */

     URL:'https://sofc01.noton.dev/cw-noton/graphql',
     Auth:'https://sauth.cw.solutions/cw-identity/oauth/token'


/* OFC-KSA Server URL'S 
=================================================== */

/*        URL:'https://sofc-ksa.noton.dev/cw-noton/graphql',
        Auth:'https://auth-ofc-ksa.cw.solutions/cw-identity/oauth/token' */


}
 
